<template>
 <div class="table-button">
  <ModalitiesTable :protocol_id="10">
    <template v-slot:tools="{ item }">
      <Btn icon="grip-vertical" color="white" />
      <Btn icon="copy" color="white" />
      <Btn icon="create" color="white" />
      <Btn
        icon="trash-alt"
        color="white"
        @click="openModal('deleteModality', item.item)"
      />
    </template>
  </ModalitiesTable>
 </div>
</template>

<script>
import ModalitiesTable from '@/views/componentsViews/modalities/ModalitiesTable.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'StyleguideModaTablesView',
  components: {
    ModalitiesTable,
    Btn,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.table-button {
  display: flex;
  gap: $gutter-half;
  z-index: 11;
  position: relative;
}
</style>
